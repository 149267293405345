// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-jupyter-notebook-js": () => import("./../../../src/templates/JupyterNotebook.js" /* webpackChunkName: "component---src-templates-jupyter-notebook-js" */),
  "component---src-templates-markdown-remark-js": () => import("./../../../src/templates/MarkdownRemark.js" /* webpackChunkName: "component---src-templates-markdown-remark-js" */),
  "component---src-templates-mdx-js": () => import("./../../../src/templates/Mdx.js" /* webpackChunkName: "component---src-templates-mdx-js" */)
}

